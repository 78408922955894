import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import "./CSS/LoginSignup.css"; // Import CSS for styling

const LoginSignup = () => {
  const [state, setState] = useState("Login"); // Toggle between login/signup form
  const [formData, setFormData] = useState({ username: "", email: "", password: "" });
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  
  useEffect(() => {
    // Check if the user is already logged in by looking for auth token
    const token = localStorage.getItem('auth-token');
    if (token) {
      setIsLoggedIn(true);
      navigate('/'); // Redirect to dashboard if already logged in
    }
  }, [backendUrl]);

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const login = async () => {
    let dataObj;
    await fetch(`${backendUrl}/auth/login`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((resp) => resp.json())
      .then((data) => { dataObj = data });
    
    if (dataObj.success) {
      localStorage.setItem('auth-token', dataObj.token);
      localStorage.setItem('username', formData.email); // Save email as username for simplicity
      localStorage.setItem('role', dataObj.role); // Store role in localStorage
      setIsLoggedIn(true); // Mark the user as logged in
      
      if (dataObj.role === "admin") {
        navigate('/'); // Redirect admin to adminDashboard
      } else {
        navigate('/'); // Redirect regular user to userDashboard
      }

      // Manually trigger the storage event to inform Navbar of login
      window.dispatchEvent(new Event('storage'));
    } else {
      alert(dataObj.errors);
    }
  };

  const signup = async () => {
    let dataObj;
    await fetch(`${backendUrl}/auth/signup`, {
      method: 'POST',
      headers: {
        Accept: 'application/form-data',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((resp) => resp.json())
      .then((data) => { dataObj = data });
    
    if (dataObj.success) {
      localStorage.setItem('auth-token', dataObj.token);
      localStorage.setItem('username', formData.username); // Save the username for later use
      setIsLoggedIn(true); // Mark the user as logged in
      navigate('/'); // Redirect to home page after successful signup

      // Manually trigger the storage event to inform Navbar of signup
      window.dispatchEvent(new Event('storage'));
    } else {
      alert(dataObj.errors);
    }
  };

  return (
    <div className="LoginSignup">
      <div className="LoginSignup-container">
        {/* Render the form for login or signup */}
        <div className="form-container">
          <h1>{state}</h1>
          <div className="LoginSignup-fields">
            {state === "Sign Up" && (
              <input
                type="text"
                placeholder="Your name"
                name="username"
                value={formData.username}
                onChange={changeHandler}
              />
            )}
            <input
              type="email"
              placeholder="User"
              name="email"
              value={formData.email}
              onChange={changeHandler}
            />
            <input
              type="password"
              placeholder="Password"
              name="password"
              value={formData.password}
              onChange={changeHandler}
            />
          </div>
          <button className="LoginSignup-button" onClick={() => (state === "Login" ? login() : signup())}>
            Continue
          </button>


        </div>
      </div>
    </div>
  );
};

export default LoginSignup;

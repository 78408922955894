// UserData.js
import React, { useState, useEffect } from 'react';

const UserData = ({ userData, setUserData, backendUrl, resetKey }) => {
  const [userProfileData, setUserProfileData] = useState({
    name: userData.name,
    email: userData.email,
    password: '',
  });

  // Reset user profile data when resetKey changes
  useEffect(() => {
    setUserProfileData({
      name: userData.name,
      email: userData.email,
      password: '',
    });
    // eslint-disable-next-line
  }, [resetKey]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserProfileData({ ...userProfileData, [name]: value });
  };

  const updateProfile = async () => {
    const token = localStorage.getItem('auth-token');
    const dataToUpdate = {
      name: userProfileData.name,
      email: userProfileData.email,
    };

    if (userProfileData.password) {
      dataToUpdate.newPassword = userProfileData.password;
    }

    try {
      const response = await fetch(`${backendUrl}/user/updateuserdata`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'auth-token': token,
        },
        body: JSON.stringify(dataToUpdate),
      });

      const result = await response.json();
      if (result.success) {
        alert('Profile updated successfully!');
        setUserData({
          ...userData,
          name: userProfileData.name,
          email: userProfileData.email,
        });
        setUserProfileData({ ...userProfileData, password: '' }); // Clear password field
      } else {
        alert('Error updating profile.');
      }
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  return (
    <div className="form-group">
      <h2>User Data</h2>
      <label htmlFor="name">Name</label>
      <input
        type="text"
        name="name"
        value={userProfileData.name}
        onChange={handleInputChange}
        placeholder="Name"
      />
      <label htmlFor="email">Email</label>
      <input
        type="email"
        name="email"
        value={userProfileData.email}
        onChange={handleInputChange}
        placeholder="Email"
      />
      <label htmlFor="password">Change Password</label>
      <input
        type="password"
        name="password"
        value={userProfileData.password}
        onChange={handleInputChange}
        placeholder="New Password"
      />
      <button onClick={updateProfile}>Update Profile</button>
    </div>
  );
};

export default UserData;

import React, { useState, useEffect } from 'react';

const ManageCategories = ({ backendUrl, resetKey }) => {
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState({
    name: '',
    displayName: '',
    description: ''
  });
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    fetchCategories();
  }, [resetKey]);

  // Función para obtener todas las categorías desde el backend
  const fetchCategories = async () => {
    const token = localStorage.getItem('auth-token');
    try {
      const response = await fetch(`${backendUrl}/category/get`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'auth-token': token,
        },
      });
      const result = await response.json();
      if (result.success) {
        setCategories(result.categories);
      } else {
        alert('Error fetching categories');
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  // Función para crear una nueva categoría
  const addCategory = async () => {
    const token = localStorage.getItem('auth-token');
    try {
      const response = await fetch(`${backendUrl}/category/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'auth-token': token,
        },
        body: JSON.stringify(newCategory),
      });
      const result = await response.json();
      if (result.success) {
        alert(result.message);
        setNewCategory({ name: '', displayName: '', description: '' });
        fetchCategories(); // Recargar la lista de categorías
      } else {
        alert(result.message);
      }
    } catch (error) {
      console.error('Error adding category:', error);
    }
  };

  // Función para actualizar una categoría
  const updateCategory = async () => {
    const token = localStorage.getItem('auth-token');
    try {
      const response = await fetch(`${backendUrl}/category/update/${selectedCategory._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'auth-token': token,
        },
        body: JSON.stringify(newCategory),
      });
      const result = await response.json();
      if (result.success) {
        alert(result.message);
        setNewCategory({ name: '', displayName: '', description: '' });
        setSelectedCategory(null);
        fetchCategories(); // Recargar las categorías
      } else {
        alert(result.message);
      }
    } catch (error) {
      console.error('Error updating category:', error);
    }
  };

  // Función para eliminar una categoría
  const deleteCategory = async (categoryId) => {
    const token = localStorage.getItem('auth-token');
    try {
      const response = await fetch(`${backendUrl}/category/delete/${categoryId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'auth-token': token,
        },
      });
      const result = await response.json();
      if (result.success) {
        alert(result.message);
        fetchCategories(); // Recargar la lista de categorías
      } else {
        alert(result.message);
      }
    } catch (error) {
      console.error('Error deleting category:', error);
    }
  };

  const handleCategoryChange = (e) => {
    const { name, value } = e.target;
    setNewCategory((prevCategory) => ({
      ...prevCategory,
      [name]: value,
    }));
  };

  const handleSelectCategory = (category) => {
    setSelectedCategory(category);
    setNewCategory({
      name: category.name,
      displayName: category.displayName,
      description: category.description,
    });
  };

  const clearSelection = () => {
    setSelectedCategory(null);
    setNewCategory({ name: '', displayName: '', description: '' });
  };

  return (
    <div className="form-group">
      <h2>Manage Categories</h2>

      {selectedCategory ? (
        <div>
          <h3>Update Category: {selectedCategory.name}</h3>
          <label htmlFor="name">Category Name</label>
          <input
            type="text"
            value={newCategory.name}
            onChange={handleCategoryChange}
            name="name"
          />
          <label htmlFor="displayName">Display Name</label>
          <input
            type="text"
            value={newCategory.displayName}
            onChange={handleCategoryChange}
            name="displayName"
          />
          <label htmlFor="description">Description</label>
          <input
            type="text"
            value={newCategory.description}
            onChange={handleCategoryChange}
            name="description"
          />
          <button onClick={updateCategory}>Update Category</button>
          <button onClick={() => deleteCategory(selectedCategory._id)}>Delete Category</button>
          <button onClick={clearSelection}>Clear Selection</button>
        </div>
      ) : (
        <>
          <h3>Create New Category</h3>
          <input
            type="text"
            value={newCategory.name}
            onChange={handleCategoryChange}
            placeholder="Enter new category name"
            name="name"
          />
          <input
            type="text"
            value={newCategory.displayName}
            onChange={handleCategoryChange}
            placeholder="Enter display name"
            name="displayName"
          />
          <input
            type="text"
            value={newCategory.description}
            onChange={handleCategoryChange}
            placeholder="Enter description"
            name="description"
          />
          <button onClick={addCategory}>Add Category</button>
        </>
      )}

      <h3>Existing Categories</h3>
      <table className="category-table">
        <thead>
          <tr>
            <th>Category Name</th>
            <th>Display Name</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {categories.map((category) => (
            <tr key={category._id} onClick={() => handleSelectCategory(category)}>
              <td>{category.name}</td>
              <td>{category.displayName}</td>
              <td>{category.description}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ManageCategories;

import React, { useState, useEffect } from 'react';
import './Navbar.css'; // Import CSS for styling
import { Link, useNavigate } from 'react-router-dom'; // Import necessary hooks for navigation
import { FaUserCircle, FaSignInAlt, FaSearch } from 'react-icons/fa'; // Import icons
import Search from '../../Components/Search/Search'; // Import the Search component

const Navbar = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [profileImage, setProfileImage] = useState(null); // Store profile image URL
  const [showSearch, setShowSearch] = useState(false); // State to control search visibility
  const navigate = useNavigate();

  // Check login state by fetching the username and profile image from localStorage
  const checkLoginStatus = () => {
    const storedUsername = localStorage.getItem('username');
    const authToken = localStorage.getItem('auth-token');
    const storedProfileImage = localStorage.getItem('profileImage'); // Assume profile image is stored
    if (authToken && storedUsername) {
      setIsLoggedIn(true); // Set user as logged in
      setProfileImage(storedProfileImage || null); // Set profile image or null if it doesn't exist
    } else {
      setIsLoggedIn(false); // User is logged out
      setProfileImage(null); // Clear the profile image
    }
  };

  useEffect(() => {
    // Check the login status on initial load
    checkLoginStatus();

    // Add an event listener to listen to storage changes (login/logout)
    const handleStorageChange = () => {
      checkLoginStatus();
    };

    window.addEventListener('storage', handleStorageChange);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const handleUserIconClick = () => {
    if (isLoggedIn) {
      navigate('/Dashboard'); // Redirect to adminDashboard if admin
    } else {
      navigate('/account'); // If user is not logged in, redirect to login page
    }
  };

  const handleLogout = () => {
    // Clear the local storage when logging out
    localStorage.removeItem('auth-token');
    localStorage.removeItem('username');
    localStorage.removeItem('profileImage'); // Clear the profile image as well
    setIsLoggedIn(false); // Set the state to logged out
    setProfileImage(null); // Clear the profile image
    navigate('/'); // Redirect to home after logout

    // Manually dispatch the storage event to trigger changes in other components
    window.dispatchEvent(new Event('storage'));
  };

  const closeSearch = () => {
    setShowSearch(false); // Function to close the search form
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        {/* Logo on the left */}
        <div className="navbar-left">
          <div className="navbar-logo">
            <Link to="/">
              <img
                src="https://dzsyhtfpcz9op.cloudfront.net/staticResources/logo_intoreality.tv.png"
                alt="Into Reality Logo"
              />
            </Link>
          </div>
        </div>

        {/* Right section with search icon and user login */}
        <div className="navbar-right">
          {/* Search Icon */}
          <div className="search-icon" onClick={() => setShowSearch(!showSearch)}>
            <FaSearch size={30} color="white" style={{ cursor: 'pointer' }} />
          </div>

          {/* Conditionally show the Search component */}
          {showSearch && <Search closeSearch={closeSearch} />}

          {/* Show the user profile image if logged in, otherwise show the login button */}
          {isLoggedIn ? (
            <div className="navbar-user">
              {/* Show profile image if available, otherwise show default user icon */}
              {profileImage ? (
                <img
                  src={profileImage}
                  alt="Profile"
                  className="profile-image"
                  onClick={handleUserIconClick}
                  style={{ cursor: 'pointer', borderRadius: '50%', width: '40px', height: '40px' }}
                />
              ) : (
                <FaUserCircle size={40} color="white" onClick={handleUserIconClick} style={{ cursor: 'pointer' }} />
              )}
              {/* Logout button */}
              <button className="logout-button" onClick={handleLogout} style={{ display: 'none' }}>
                Logout
              </button>
            </div>
          ) : (
            <FaSignInAlt size={40} color="white" onClick={handleUserIconClick} style={{ cursor: 'pointer' }} />
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

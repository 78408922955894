// ManageUsers.js
import React, { useState, useEffect } from 'react';

const ManageUsers = ({ backendUrl, resetKey }) => {
  const [users, setUsers] = useState([]);
  const [newUserData, setNewUserData] = useState({
    name: '',
    email: '',
    password: '',
    role: 'user',
  });
  const [selectedUser, setSelectedUser] = useState(null);
  const [userPasswords, setUserPasswords] = useState({});
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const [nameFilter, setNameFilter] = useState('');
  const [roleFilter, setRoleFilter] = useState('');

  // Fetch users and reset state when resetKey changes
  useEffect(() => {
    fetchUsers();
    // Reset internal state
    setSelectedUser(null);
    setNewUserData({
      name: '',
      email: '',
      password: '',
      role: 'user',
    });
    setUserPasswords({});
    // eslint-disable-next-line
  }, [resetKey]);

  // Fetch all users (admin functionality)
  const fetchUsers = async () => {
    const token = localStorage.getItem('auth-token');
    try {
      const response = await fetch(`${backendUrl}/user/getusers`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'auth-token': token,
        },
      });
      const result = await response.json();
      if (result.success) {
        setUsers(result.users);
      } else {
        console.error('Error fetching users:', result.message);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleNewUserChange = (e) => {
    const { name, value } = e.target;
    setNewUserData({ ...newUserData, [name]: value });
  };

  const createNewUser = async () => {
    // ... existing code
    const token = localStorage.getItem('auth-token');
    try {
      const response = await fetch(`${backendUrl}/user/createuser`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'auth-token': token,
        },
        body: JSON.stringify(newUserData),
      });
      const result = await response.json();
      if (result.success) {
        alert('New user created successfully!');
        setNewUserData({ name: '', email: '', password: '', role: 'user' });
        fetchUsers();
      } else {
        alert('Error creating new user.');
      }
    } catch (error) {
      console.error('Error creating new user:', error);
    }
  };

  const handleSelectUser = (user) => {
    setSelectedUser(user);
  };

  const handleUserPasswordChange = (e, userId) => {
    const { value } = e.target;
    setUserPasswords({
      ...userPasswords,
      [userId]: value,
    });
  };

  const updateProfile = async () => {
    // ... existing code
    const token = localStorage.getItem('auth-token');
    const dataToUpdate = {
      userId: selectedUser._id,
      name: selectedUser.name,
      email: selectedUser.email,
      role: selectedUser.role,
    };

    try {
      const response = await fetch(`${backendUrl}/user/updateuserdata`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'auth-token': token,
        },
        body: JSON.stringify(dataToUpdate),
      });

      const result = await response.json();
      if (result.success) {
        alert('Profile updated successfully!');
        setSelectedUser(null);
        fetchUsers();
      } else {
        alert('Error updating profile.');
      }
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  const changeUserPassword = async (userId, newPassword) => {
  if (!newPassword) {
    return;
  }

  const token = localStorage.getItem('auth-token');
  try {
    const response = await fetch(`${backendUrl}/user/changepassword`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'auth-token': token,
      },
      body: JSON.stringify({ userId, newPassword }),
    });
    
    const result = await response.json();

    if (result.success) {
      alert('Password updated successfully!');
      setUserPasswords({
        ...userPasswords,
        [userId]: '',
      });
    } else {
      alert(result.message); // Show the message sent from the backend, like validation errors
    }
  } catch (error) {
    console.error('Error updating user password:', error);
    alert('Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one number, and one special character.');
  }
};

  const deleteUser = async (userId) => {
    // ... existing code
    const token = localStorage.getItem('auth-token');
    try {
      const response = await fetch(`${backendUrl}/user/deleteuser`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'auth-token': token,
        },
        body: JSON.stringify({ userId }),
      });
      const result = await response.json();
      if (result.success) {
        alert('User deleted successfully!');
        fetchUsers();
        setSelectedUser(null);
      } else {
        alert('Error deleting user.');
      }
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  const handleUpdateUserData = async () => {
    if (selectedUser) {
      await updateProfile();
      if (userPasswords[selectedUser._id]) {
        await changeUserPassword(selectedUser._id, userPasswords[selectedUser._id]);
      }
    }
  };

  const sortUsers = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const filteredUsers = React.useMemo(() => {
    let filtered = [...users];

    if (nameFilter) {
      filtered = filtered.filter((user) =>
        user.name.toLowerCase().includes(nameFilter.toLowerCase())
      );
    }

    if (roleFilter) {
      filtered = filtered.filter((user) => user.role === roleFilter);
    }

    return filtered;
  }, [users, nameFilter, roleFilter]);

  const sortedUsers = React.useMemo(() => {
    let sortableUsers = [...filteredUsers];
    if (sortConfig.key) {
      sortableUsers.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableUsers;
  }, [filteredUsers, sortConfig]);

  return (
    <div className="form-group">
      <h2>Manage Users</h2>

      {selectedUser ? (
        <div className="form-group">
          <h3>Update User: {selectedUser.name}</h3>
          <label htmlFor="name">Name</label>
          <input
            type="text"
            value={selectedUser.name}
            onChange={(e) =>
              setSelectedUser({ ...selectedUser, name: e.target.value })
            }
          />
          <label htmlFor="email">Email</label>
          <input
            type="email"
            value={selectedUser.email}
            onChange={(e) =>
              setSelectedUser({ ...selectedUser, email: e.target.value })
            }
          />
          <label htmlFor="role">Role</label>
          <select
            value={selectedUser.role}
            onChange={(e) =>
              setSelectedUser({ ...selectedUser, role: e.target.value })
            }
          >
            <option value="user">User</option>
            <option value="admin">Admin</option>
          </select>
          <label htmlFor="password">Change Password</label>
          <input
            type="password"
            placeholder="New Password"
            value={userPasswords[selectedUser._id] || ''}
            onChange={(e) => handleUserPasswordChange(e, selectedUser._id)}
          />
          <button onClick={handleUpdateUserData}>Update User Data</button>
          <button onClick={() => deleteUser(selectedUser._id)}>Delete User</button>
          <button onClick={() => setSelectedUser(null)}>Back to List</button>
        </div>
      ) : (
        <>
          <div>
            <label>
              Filter by Name:
              <input
                type="text"
                value={nameFilter}
                onChange={(e) => setNameFilter(e.target.value)}
              />
            </label>
            <label>
              Filter by Role:
              <select
                value={roleFilter}
                onChange={(e) => setRoleFilter(e.target.value)}
              >
                <option value="">All</option>
                <option value="user">User</option>
                <option value="admin">Admin</option>
              </select>
            </label>
          </div>
          <table>
            <thead>
              <tr>
                <th onClick={() => sortUsers('name')}>Name</th>
                <th onClick={() => sortUsers('email')}>Email</th>
                <th onClick={() => sortUsers('role')}>Role</th>
              </tr>
            </thead>
            <tbody>
              {sortedUsers.map((user, index) => (
                <tr
                  key={user._id}
                  onClick={() => handleSelectUser(user)}
                  style={{
                    backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#fff',
                  }}
                >
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>{user.role}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <h3>Create New User</h3>
          <div className="form-group">
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={newUserData.name}
              onChange={handleNewUserChange}
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={newUserData.email}
              onChange={handleNewUserChange}
            />
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={newUserData.password}
              onChange={handleNewUserChange}
            />
            <label htmlFor="role">Role</label>
            <select
              name="role"
              value={newUserData.role}
              onChange={handleNewUserChange}
            >
              <option value="user">User</option>
              <option value="admin">Admin</option>
            </select>
            <button onClick={createNewUser}>Create User</button>
          </div>
        </>
      )}
    </div>
  );
};

export default ManageUsers;

import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import './Search.css'; // Import the search-specific styles

const Search = ({ closeSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const searchRef = useRef(null); // Reference for the search popup

  const handleSearch = async (e) => {
    setSearchTerm(e.target.value);
    if (e.target.value.trim()) {
      setLoading(true);
      setError('');
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/product/search`, {
          params: {
            searchString: e.target.value,
          },
        });
        setSearchResults(response.data.products); // Update search results
        if (response.data.products.length === 0) {
          setError('No products found'); // Set the error only if no products are found
        } else {
          setError(''); // Clear any previous error
        }
      } catch (err) {
        setError('No products found!');
        setSearchResults([]);
      } finally {
        setLoading(false);
      }
    } else {
      setSearchResults([]);
      setError(''); // Clear error if input is cleared
    }
  };

  // Use effect to handle clicks outside the search box
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        closeSearch(); // Call the function to close the search form
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [searchRef, closeSearch]);

  return (
    <div className="search-popup" ref={searchRef}>
      <input
        type="text"
        value={searchTerm}
        onChange={handleSearch}
        placeholder="Search products..."
        className="search-input"
      />
      {/* Show search results only if there are results */}
      {searchResults.length > 0 && (
        <div className="search-results">
          <ul>
            {searchResults.map((result, index) => (
              <li key={index} className="search-item">
                {/* Hacer clic en la imagen también redirige */}
                <a href={`/product/${result._id}`} className="search-item-link">
                  <img
                    src={result.featuredImage}
                    alt={result.title}
                    className="search-item-image"
                  />
                </a>
                {/* El título también es clicable */}
                <a href={`/product/${result._id}`} className="search-item-title">
                  {result.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Show error message if no products are found */}
      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default Search;

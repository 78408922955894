import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Updated to useNavigate

const ManageProducts = ({ backendUrl, resetKey }) => {
  const [products, setProducts] = useState([]);
  const [events, setEvents] = useState([]);
  const [categories, setCategories] = useState([]); // Lista de categorías desde el backend
  const [newProduct, setNewProduct] = useState({
    title: '',
    price: '',
    event: '',
    status: true,
    featuredImage: '',
    category: [], // Almacena los IDs de las categorías seleccionadas
    description: '', // Add description to newProduct state
  });
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const [titleFilter, setTitleFilter] = useState('');
  const [eventFilter, setEventFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [categoryFilter, setCategoryFilter] = useState(''); // Filtro por categoría
  const navigate = useNavigate(); // Replacing useHistory

  useEffect(() => {
    const fetchData = async () => {
      await fetchProducts();
      await fetchEvents();
      await fetchCategories(); // Cargar las categorías desde el backend
      setSelectedProduct(null);
      setNewProduct({
        title: '',
        price: '',
        event: '',
        status: true,
        featuredImage: '',
        category: [], // Reinicia el array de categorías
        description: '', // Reset description
      });
    };

    fetchData();
  }, [resetKey]);

  const fetchProducts = async () => {
    const token = localStorage.getItem('auth-token');
    try {
      const response = await fetch(`${backendUrl}/product/get`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'auth-token': token,
        },
      });
      const result = await response.json();
      if (result.success) {
        setProducts(result.products);
      } else {
        alert('Error fetching Products');
      }
    } catch (error) {
      console.error('Error fetching Products:', error);
    }
  };

  const fetchEvents = async () => {
    const token = localStorage.getItem('auth-token');
    try {
      const response = await fetch(`${backendUrl}/event/get`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'auth-token': token,
        },
      });
      const result = await response.json();
      if (result.success) {
        setEvents(result.events);
      } else {
        alert('Error fetching Events');
      }
    } catch (error) {
      console.error('Error fetching Events:', error);
    }
  };

  const fetchCategories = async () => {
    const token = localStorage.getItem('auth-token');
    try {
      const response = await fetch(`${backendUrl}/category/get`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'auth-token': token,
        },
      });
      const result = await response.json();
      if (result.success) {
        setCategories(result.categories);
      } else {
        alert('Error fetching Categories');
      }
    } catch (error) {
      console.error('Error fetching Categories:', error);
    }
  };

  const handleNewProductChange = (e) => {
    const { name, value } = e.target;
    setNewProduct({ ...newProduct, [name]: value });
  };

  const handleSelectedProductChange = (e) => {
    const { name, value } = e.target;
    setSelectedProduct({ ...selectedProduct, [name]: value });
  };

  const handleCategoryChange = (e) => {
    const { value } = e.target;
    if (!newProduct.category.includes(value)) {
      setNewProduct((prevProduct) => ({
        ...prevProduct,
        category: [...prevProduct.category, value], // Añadir el ID de la categoría
      }));
    }
  };

  const handleCategoryRemove = (categoryId) => {
    setNewProduct((prevProduct) => ({
      ...prevProduct,
      category: prevProduct.category.filter((cat) => cat !== categoryId),
    }));
  };

  const handleSelectedCategoryChange = (e) => {
    const { value } = e.target;
    if (!selectedProduct.category.includes(value)) {
      setSelectedProduct((prevProduct) => ({
        ...prevProduct,
        category: [...prevProduct.category, value], // Añadir el ID de la categoría al actualizar
      }));
    }
  };

  const handleSelectedCategoryRemove = (categoryId) => {
    setSelectedProduct((prevProduct) => ({
      ...prevProduct,
      category: prevProduct.category.filter((cat) => cat !== categoryId),
    }));
  };

  const handleSelectProduct = (product) => {
    // Populate selected categories on product selection
    setSelectedProduct({
      ...product,
      category: product.category.map((cat) => cat._id), // Map to category IDs
    });
  };

  const createProduct = async () => {
    const token = localStorage.getItem('auth-token');
    try {
      const response = await fetch(`${backendUrl}/product/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'auth-token': token,
        },
        body: JSON.stringify({
          ...newProduct,
          event: newProduct.event._id || newProduct.event,
          category: newProduct.category, // Asegúrate de enviar los IDs de las categorías
          description: newProduct.description, // Send description
        }),
      });
      const result = await response.json();
      if (result.success) {
        alert('New Product added successfully!');
        setNewProduct({
          title: '',
          price: '',
          event: '',
          status: true,
          featuredImage: '',
          category: [], // Reiniciar array de categorías
          description: '', // Reset description
        });
        fetchProducts();
      } else {
        alert('Error adding Product.');
      }
    } catch (error) {
      console.error('Error adding Product:', error);
    }
  };

  const updateProduct = async () => {
    const token = localStorage.getItem('auth-token');
    try {
      const response = await fetch(`${backendUrl}/product/update/${selectedProduct._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'auth-token': token,
        },
        body: JSON.stringify({
          ...selectedProduct,
          event: selectedProduct.event._id || selectedProduct.event,
          category: selectedProduct.category, // Asegúrate de enviar los IDs de las categorías
          description: selectedProduct.description, // Send description
        }),
      });
      const result = await response.json();
      if (result.success) {
        alert('Product updated successfully!');
        setSelectedProduct(null);
        await fetchProducts();
        await fetchEvents();
      } else {
        alert('Error updating Product.');
      }
    } catch (error) {
      console.error('Error updating Product:', error);
    }
  };

  const deleteProduct = async (productId) => {
    const token = localStorage.getItem('auth-token');
    try {
      const response = await fetch(`${backendUrl}/product/delete/${productId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'auth-token': token,
        },
      });
      const result = await response.json();
      if (result.success) {
        alert('Product deleted successfully!');
        setSelectedProduct(null);
        fetchProducts();
      } else {
        alert('Error deleting Product.');
      }
    } catch (error) {
      console.error('Error deleting Product:', error);
    }
  };

  const sortProducts = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const filteredProducts = React.useMemo(() => {
    let filtered = [...products];

    if (titleFilter) {
      filtered = filtered.filter((product) =>
        product.title.toLowerCase().includes(titleFilter.toLowerCase())
      );
    }

    if (eventFilter) {
      filtered = filtered.filter(
        (product) =>
          (product.event?.title || '').toLowerCase().includes(eventFilter.toLowerCase())
      );
    }

    if (statusFilter) {
      const statusBool = statusFilter === 'Active';
      filtered = filtered.filter((product) => product.status === statusBool);
    }

    if (categoryFilter) {
      filtered = filtered.filter((product) =>
        product.category.some((cat) => cat._id === categoryFilter)
      );
    }

    return filtered;
  }, [products, titleFilter, eventFilter, statusFilter, categoryFilter]);

  const sortedProducts = React.useMemo(() => {
    let sortableProducts = [...filteredProducts];
    if (sortConfig.key) {
      sortableProducts.sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];

        if (sortConfig.key === 'eventTitle') {
          aValue = a.event?.title || '';
          bValue = b.event?.title || '';
        }

        if (sortConfig.key === 'status') {
          aValue = aValue ? 1 : 0;
          bValue = bValue ? 1 : 0;
        }

        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableProducts;
  }, [filteredProducts, sortConfig]);

  // Navigating to Manage Categories
  const navigateToManageCategories = () => {
    navigate('/manage-categories'); // Assuming your route is '/manage-categories'
  };

  return (
    <div className="form-group">
      <h2>Manage Products</h2>

      {selectedProduct ? (
        <div className="form-group">
          <h3>Update Product: {selectedProduct.title}</h3>
          {/* Formulario para actualizar el producto */}
          <label htmlFor="title">Title</label>
          <input
            type="text"
            value={selectedProduct.title}
            onChange={handleSelectedProductChange}
            name="title"
          />
          <label htmlFor="price">Price</label>
          <input
            type="number"
            value={selectedProduct.price}
            onChange={handleSelectedProductChange}
            name="price"
          />
          <label htmlFor="event">Event</label>
          <select
            value={selectedProduct.event?._id || selectedProduct.event}
            onChange={handleSelectedProductChange}
            name="event"
          >
            {events.map((event) => (
              <option key={event._id} value={event._id}>
                {event.title}
              </option>
            ))}
          </select>
          <label htmlFor="featuredImage">Featured Image URL</label>
          <input
            type="text"
            value={selectedProduct.featuredImage}
            onChange={handleSelectedProductChange}
            name="featuredImage"
            placeholder="Image URL"
          />
          <label htmlFor="status">Status</label>
          <div className="radio-group">
            <input
              type="radio"
              id="statusActive"
              name="status"
              value={true}
              checked={selectedProduct.status === true}
              onChange={() => setSelectedProduct({ ...selectedProduct, status: true })}
            />
            <label
              htmlFor="statusActive"
              className={`radio-label ${selectedProduct.status === true ? 'selected' : ''}`}
            >
              Active
            </label>

            <input
              type="radio"
              id="statusInactive"
              name="status"
              value={false}
              checked={selectedProduct.status === false}
              onChange={() => setSelectedProduct({ ...selectedProduct, status: false })}
            />
            <label
              htmlFor="statusInactive"
              className={`radio-label ${selectedProduct.status === false ? 'selected' : ''}`}
            >
              Inactive
            </label>
          </div>
          <label>Description</label>
          <textarea
            name="description"
            value={selectedProduct.description}
            onChange={handleSelectedProductChange}
            placeholder="Enter product description"
          />
          <label>Categories</label>
          <div className="checkbox-group">
            <select onChange={handleSelectedCategoryChange}>
              <option value="">Select Category</option>
              {categories.map((category) => (
                <option key={category._id} value={category._id}>
                  {category.name}
                </option>
              ))}
            </select>
            <ul>
              {selectedProduct.category.map((categoryId) => {
                const category = categories.find((cat) => cat._id === categoryId);
                return (
                  <li key={categoryId}>
                    {category?.name}
                    <button onClick={() => handleSelectedCategoryRemove(categoryId)}>Remove</button>
                  </li>
                );
              })}
            </ul>
          </div>
          <button onClick={updateProduct}>Update Product</button>
          <button onClick={() => deleteProduct(selectedProduct._id)}>Delete Product</button>
          <button onClick={() => setSelectedProduct(null)}>Back to List</button>
        </div>
      ) : (
        <>
          <div>
            <label>
              Filter by Title:
              <input
                type="text"
                value={titleFilter}
                onChange={(e) => setTitleFilter(e.target.value)}
              />
            </label>
            <label>
              Filter by Event:
              <input
                type="text"
                value={eventFilter}
                onChange={(e) => setEventFilter(e.target.value)}
              />
            </label>
            <label>
              Filter by Status:
              <select
                value={statusFilter}
                onChange={(e) => setStatusFilter(e.target.value)}
              >
                <option value="">All</option>
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </select>
            </label>
            <label>
              Filter by Category:
              <select
                value={categoryFilter}
                onChange={(e) => setCategoryFilter(e.target.value)}
              >
                <option value="">All</option>
                {categories.map((category) => (
                  <option key={category._id} value={category._id}>
                    {category.name}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <table>
            <thead>
              <tr>
                <th onClick={() => sortProducts('title')}>Title</th>
                <th onClick={() => sortProducts('price')}>Price</th>
                <th onClick={() => sortProducts('eventTitle')}>Event</th>
                <th onClick={() => sortProducts('status')}>Status</th>
              </tr>
            </thead>
            <tbody>
              {sortedProducts.map((product, index) => (
                <tr
                  key={product._id}
                  onClick={() => handleSelectProduct(product)}
                  style={{
                    backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#fff',
                  }}
                >
                  <td>{product.title}</td>
                  <td>{product.price}</td>
                  <td>{product.event?.title || 'N/A'}</td>
                  <td>{product.status ? 'Active' : 'Inactive'}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <h3>Create New Product</h3>
          <div className="form-group">
            <input
              type="text"
              name="title"
              placeholder="Product Title"
              value={newProduct.title}
              onChange={handleNewProductChange}
            />
            <input
              type="number"
              name="price"
              placeholder="Price"
              value={newProduct.price}
              onChange={handleNewProductChange}
            />
            <label htmlFor="event">Event</label>
            <select
              name="event"
              value={newProduct.event?._id || newProduct.event}
              onChange={handleNewProductChange}
            >
              <option value="">Select Event</option>
              {events.map((event) => (
                <option key={event._id} value={event._id}>
                  {event.title}
                </option>
              ))}
            </select>
            <label htmlFor="featuredImage">Featured Image URL</label>
            <input
              type="text"
              name="featuredImage"
              placeholder="Image URL"
              value={newProduct.featuredImage}
              onChange={handleNewProductChange}
            />
            <label htmlFor="status">Status</label>
            <div className="radio-group">
              <input
                type="radio"
                id="statusActive"
                name="status"
                value={true}
                checked={newProduct.status === true}
                onChange={() => setNewProduct({ ...newProduct, status: true })}
              />
              <label
                htmlFor="statusActive"
                className={`radio-label ${newProduct.status === true ? 'selected' : ''}`}
              >
                Active
              </label>

              <input
                type="radio"
                id="statusInactive"
                name="status"
                value={false}
                checked={newProduct.status === false}
                onChange={() => setNewProduct({ ...newProduct, status: false })}
              />
              <label
                htmlFor="statusInactive"
                className={`radio-label ${newProduct.status === false ? 'selected' : ''}`}
              >
                Inactive
              </label>
            </div>
            <label>Description</label>
            <textarea
              name="description"
              value={newProduct.description}
              onChange={handleNewProductChange}
              placeholder="Enter product description"
            />
            <label>Categories</label>
            <div className="checkbox-group">
              <select onChange={handleCategoryChange}>
                <option value="">Select Category</option>
                {categories.map((category) => (
                  <option key={category._id} value={category._id}>
                    {category.name}
                  </option>
                ))}
              </select>
              <ul>
                {newProduct.category.map((categoryId) => {
                  const category = categories.find((cat) => cat._id === categoryId);
                  return (
                    <li key={categoryId}>
                      {category?.name}
                      <button onClick={() => handleCategoryRemove(categoryId)}>Remove</button>
                    </li>
                  );
                })}
              </ul>
            </div>
            <button onClick={createProduct}>Add Product</button>
          </div>
        </>
      )}
    </div>
  );
};

export default ManageProducts;

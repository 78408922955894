import React, { useState, useEffect } from 'react';
import './CSS/Dashboard.css';
import ManageUsers from './ManageUsers';
import ManageEvents from './ManageEvents';
import ManageProducts from './ManageProducts';
import ManageCategories from './ManageCategories'; // Import the new ManageCategories component
import UserData from './UserData';

const Dashboard = () => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [userData, setUserData] = useState({
    name: '',
    email: '',
    role: '',
  });
  const [activeTab, setActiveTab] = useState('profile');
  const [loading, setLoading] = useState(true);

  // State to track reset keys for each tab
  const [tabResetKeys, setTabResetKeys] = useState({
    profile: 0,
    manageUsers: 0,
    manageEvents: 0,
    manageProducts: 0,
    manageCategories: 0, // Added reset key for categories tab
  });

  // Fetch current user/admin data when the component mounts
  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('auth-token');
      if (!token) {
        // Redirect to login if no token is found
        window.location.href = '/account';
      } else {
        try {
          const response = await fetch(`${backendUrl}/user/getuserdata`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'auth-token': token,
            },
          });
          const result = await response.json();
          if (result.success) {
            setUserData({
              name: result.name,
              email: result.email,
              role: result.role,
            });
            setLoading(false);
          } else {
            console.error('Error fetching user data:', result.message);
            setLoading(false);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          setLoading(false);
        }
      }
    };

    fetchUserData();
  }, [backendUrl]);

  const handleTabChange = (tab) => {
    if (tab === activeTab) {
      // Increment the reset key for the current tab to trigger a reset
      setTabResetKeys((prevKeys) => ({
        ...prevKeys,
        [tab]: prevKeys[tab] + 1,
      }));
    } else {
      setActiveTab(tab);
    }
  };

  // If loading, show loading spinner
  if (loading) {
    return <div className="loading"></div>;
  }

  return (
    <div className="Dashboard">
      <div className="sidebar">
        <ul>
          <li
            className={activeTab === 'profile' ? 'active' : ''}
            onClick={() => handleTabChange('profile')}
          >
            User Data
          </li>
          {userData.role === 'admin' && (
            <>
              <li
                className={activeTab === 'manageUsers' ? 'active' : ''}
                onClick={() => handleTabChange('manageUsers')}
              >
                Manage Users
              </li>
              <li
                className={activeTab === 'manageEvents' ? 'active' : ''}
                onClick={() => handleTabChange('manageEvents')}
              >
                Manage Events
              </li>
              <li
                className={activeTab === 'manageProducts' ? 'active' : ''}
                onClick={() => handleTabChange('manageProducts')}
              >
                Manage Products
              </li>
              <li
                className={activeTab === 'manageCategories' ? 'active' : ''}
                onClick={() => handleTabChange('manageCategories')}
              >
                Manage Categories
              </li>
            </>
          )}
        </ul>
      </div>
      <div className="dashboard-content">
        <h1>
          {userData.role === 'admin' ? 'Admin Dashboard' : 'User Dashboard'}
        </h1>

        {activeTab === 'profile' && (
          <UserData
            userData={userData}
            setUserData={setUserData}
            backendUrl={backendUrl}
            resetKey={tabResetKeys.profile}
          />
        )}

        {activeTab === 'manageUsers' && userData.role === 'admin' && (
          <ManageUsers backendUrl={backendUrl} resetKey={tabResetKeys.manageUsers} />
        )}

        {activeTab === 'manageEvents' && userData.role === 'admin' && (
          <ManageEvents backendUrl={backendUrl} resetKey={tabResetKeys.manageEvents} />
        )}

        {activeTab === 'manageProducts' && userData.role === 'admin' && (
          <ManageProducts backendUrl={backendUrl} resetKey={tabResetKeys.manageProducts} />
        )}

        {activeTab === 'manageCategories' && userData.role === 'admin' && (
          <ManageCategories backendUrl={backendUrl} resetKey={tabResetKeys.manageCategories} />
        )}

        <button
          className="logout-button"
          onClick={() => {
            localStorage.removeItem('auth-token');
            window.location.href = '/';
          }}
        >
          Logout
        </button>
      </div>
    </div>
  );
};

export default Dashboard;

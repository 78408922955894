import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './CSS/ProductTemplate.css';
import { initIRPlayer } from '../intoreality-player/src/init';

window.isProductTemplateLoaded = false;

function ProductTemplate() {
  const navigate = useNavigate();
  const { productId } = useParams();
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const checkLogin = () => {
    const token = localStorage.getItem('auth-token');
    return token;
  };

  useEffect(() => {
    window.isProductTemplateLoaded = true;

    const loadEvent = new Event('load');
    window.dispatchEvent(loadEvent);

    if (!checkLogin()) {
      navigate('/account');
      return;
    }

    const fetchProductAndEvent = async () => {
      try {
        const productResponse = await fetch(`${backendUrl}/product/get/${productId}`);
        const productData = await productResponse.json();

        if (productData.success) {
          const product = productData.product;
          if (!product.status) {
            setError('This product is inactive.');
            setLoading(false);
            return;
          }

          const eventId = product.event._id;
          const eventResponse = await fetch(`${backendUrl}/event/get/${eventId}`);
          const eventData = await eventResponse.json();

          if (eventData.success) {
            setEvent(eventData.event);
          } else {
            setError('Event not found');
          }
        } else {
          setError('Product not found');
        }
      } catch (err) {
        console.error(err);
        setError('An error occurred while fetching the product or event');
      } finally {
        setLoading(false);
      }
    };

    fetchProductAndEvent();

    return () => {
      window.isProductTemplateLoaded = false;
    };
  }, [productId, navigate, backendUrl]);

  useEffect(() => {
    if (event) {
      const eventInformation = {
        allowed_referers: event.allowed_referers || [],
        audio_imgs: event.audio_imgs || [],
        audio_names: event.audio_names || [],
        cam_imgs: event.cam_imgs || [],
        cam_names: event.cam_names || [],
		captions_imgs: event.captions_imgs || [],
        canBeIframe: event.canBeIframe || false,
        product_id: productId,
        video_types: event.video_types || [],
        sources: event.sources || []
      };

      initIRPlayer('my-video', eventInformation);
    }
  }, [event, productId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
      <video-js id="my-video" class="video-js vjs-tech vjs-fluid vjs-default-skin vjs-16-9" controls="controls"
                crossorigin="anonymous" playsinline preload="auto" width="640" height="264">
      </video-js>
  );
}

export default ProductTemplate;
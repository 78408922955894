import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/Footer/Footer";
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from './Pages/Home';
import LoginSignup from "./Pages/LoginSignup"; // Import LoginSignup component
import UserDashboard from "./Pages/Dashboard"; // Import UserDashboard component
import ProductTemplate from './Pages/ProductTemplate'; // Import the dynamic product page template

function App() {
	 const [user, setUser] = useState(null);
return (
      <Router>
        <AppContent user={user} />
      </Router>
  );
}

function AppContent({ user }) {
  const location = useLocation();
 // Regular expression to match '/product/:productId' routes
  const isProductPage = /^\/product\/[a-zA-Z0-9]+$/.test(location.pathname);
  return (
      <div className="App">
        {/* Show Navbar unless it's a product page */}
		{!isProductPage && <Navbar user={user} />}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/account" element={<LoginSignup />} /> {/* Add route for LoginSignup */}
          <Route path="/Dashboard" element={<UserDashboard />} /> {/* Add route for UserDashboard */}
          <Route path="/product/:productId" element={<ProductTemplate />} /> {/* Dynamic product route */}
        </Routes>
		{/* Show Footer unless it's a product page */}
		{!isProductPage && <Footer user={user} />}
      </div>
  );
}

export default App;




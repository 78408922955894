// src/Components/Slider/Slider.js

import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import './Slider.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useNavigate } from 'react-router-dom';

function ProductCarousel({ category, displayName, products: propsProducts }) {
  // State variables
  const [products, setProducts] = useState(propsProducts || []);
  const [displayProducts, setDisplayProducts] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isDragging, setIsDragging] = useState(false);

  const navigate = useNavigate();
  const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:4000';

  // Fetch products if not provided via props
  useEffect(() => {
    const fetchProductsByCategory = async () => {
      try {
        const token = localStorage.getItem('auth-token');

        // Fetch products by category name
        const productResponse = await fetch(`${backendUrl}/product/category/${category}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'auth-token': token,
          },
        });

        const productResult = await productResponse.json();
        if (productResult.success) {
          setProducts(productResult.products);
        } else {
          console.error('Error fetching products by category:', productResult.message);
        }
      } catch (error) {
        console.error('Error fetching products by category:', error);
      }
    };

    if (!propsProducts || propsProducts.length === 0) {
      fetchProductsByCategory();
    }
  }, [category, backendUrl, propsProducts]);

  // Listen for window resize events to update windowWidth
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Determine the number of slides to show based on the window width
  const getSlidesToShow = () => {
    if (windowWidth >= 1200) return 5;
    if (windowWidth >= 992) return 4;
    if (windowWidth >= 768) return 3;
    if (windowWidth >= 576) return 2;
    return 2;
  };

  // Update displayProducts whenever products or windowWidth changes
  useEffect(() => {
    const slidesToShow = getSlidesToShow();
    let tempProducts = [...products];

    // Duplicate products to fill the carousel if there are not enough
    if (products.length > 0 && products.length < slidesToShow) {
      while (tempProducts.length < slidesToShow) {
        tempProducts = tempProducts.concat(products);
      }
      tempProducts = tempProducts.slice(0, slidesToShow);
    }

    setDisplayProducts(tempProducts);
  }, [products, windowWidth]);

  // Handle click on product image
  const handleProductClick = (productId) => {
    if (isDragging) return;

    const token = localStorage.getItem('auth-token');
    if (!token) {
      navigate('/account');
    } else {
      navigate(`/product/${productId}`);
    }
  };

  // Do not render the carousel if there are no products
  if (!products || products.length === 0) {
    return null;
  }

  // Slider settings
  const settings = {
    key: windowWidth,
    infinite: true,
    speed: 500,
    slidesToShow: getSlidesToShow(),
    slidesToScroll: getSlidesToShow(),
    autoplay: false,
    autoplaySpeed: 2000,
    arrows: true,
    nextArrow: <div className="slick-next">→</div>,
    prevArrow: <div className="slick-prev">←</div>,
    beforeChange: () => setIsDragging(true),
    afterChange: () => setTimeout(() => setIsDragging(false), 50),
  };

  return (
    <div className="product-carousel-container">
      <h2 className="slider-title">{displayName}</h2>
      <Slider {...settings}>
        {displayProducts.map((product, index) => (
          <div key={`${product._id}-${index}`} className="carousel-slide">
            <img
              src={product.featuredImage}
              alt={product.title}
              className="product-image"
              loading="lazy"
              onClick={() => handleProductClick(product._id)}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default ProductCarousel;
